
<template>
    <div class="chart-detailed-list">
      <h3 class="chart-detailed-title">Chart Detailed Info</h3>
      <div v-if="chartDetailedInfo !== null">
        <ul>
          <li>exchange : {{ chartDetailedInfo.exchange }}</li>
          <li>market : {{ chartDetailedInfo.market }}</li>
          <li>timeframe : {{ chartDetailedInfo.timeframe }}</li>
          <li>pattern : {{ chartDetailedInfo.pattern }}</li>
          <li>model : {{ chartDetailedInfo.model }}</li>
          <li>probability : {{ chartDetailedInfo.probability }}</li>
          <li>from : {{ chartDetailedInfo.date_from }}</li>
          <li>to : {{ chartDetailedInfo.date_to }}</li>
          <li>from : {{ chartDetailedInfo.timestamp_from }}</li>
          <li>to : {{ chartDetailedInfo.timestamp_to }}</li>
          <!-- <li>file_timestamp : {{ chartDetailedInfo.file_timestamp }}</li> -->
        </ul>
      </div>
      <div v-else></div>        

      <!-- <ul>
        <li v-for="fileName in fileNames" :key="fileName">{{ fileName }}</li>
      </ul> -->
    </div> 
</template>
  
<script setup>
  // Define props
//  const { firstProperty, secondProperty } = props;
    import { defineProps } from 'vue';

//    defineProps(['bucketName', 'fileNames'])
    // const { bucketName, fileNames } = defineProps(['bucketName', 'fileNames']);
    const props = defineProps(['chartDetailedInfo']);
    console.log("props ", props)
    console.log("props.chartDetailedInfo ", props.chartDetailedInfo)
//    console.log("props.fileNames ", props.fileNames)
//    import { ref } from 'vue';
    // const bucketName = ref('pattern-detector');
    // const fileNames = ref(['file1.jpg', 'file2.jpg']); // Default value for fileNames

</script>
  


<style scoped> 

.chart-detailed-title {
    justify-content:left;
    text-align:center;

  }

  .chart-detailed-list {
    justify-content:left;
    text-align:justify;

  }

</style>