import AWS from 'aws-sdk';

const awsConfigPlugin = {
  install(app) {
    // Set up AWS SDK with global configuration
    AWS.config.update({
      accessKeyId: 'aLgxJIs7bKBOquhGBSMI',
      secretAccessKey: 'ZFJQDi1ZreZcqaobaM96B1UrrRwr0kmIwSPeuMgZ',
      region: '', // Region doesn't matter for S3-compatible servers, but it's still required
      endpoint: 'http://s3.devnull.group:9000', // Replace with your S3-compatible server endpoint
      s3ForcePathStyle: true // Enable this option for S3-compatible servers
    });

    const s3BucketName = "pattern-detector";
    //const $s3BucketName = 'pattern-publisher';
    app.config.globalProperties.s3BucketName = s3BucketName;

    console.log(`s3BucketName set to: ${s3BucketName}`);
  }
};

export default awsConfigPlugin;
