import { createApp } from 'vue'
import App from './App.vue'
import awsConfigPlugin from './plugins/awsConfig';

const app = createApp(App);
// Use the plugin
app.use(awsConfigPlugin);

//app.config.globalProperties.s3BucketName = "pattern-detector";

app.mount('#app');

//createApp(App).mount('#app')
