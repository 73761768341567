<!-- ChartsList.vue -->
<template>
  <!-- <h1>ssasd sadDDVD edfesf</h1>
  <p>
    sdasdasd dfsfds dfs dsf
  </p> -->
  <div class="container">

    <div class="column left-column">

      <div class="selector">
        <div class="selector-cell">
          <p class="selector-label">Exchange</p>
        </div>
        <div class="selector-cell"> 
          <select v-model="selectedExchange" class="input-select" @change="fetchMarkets(); fetchCharts()">
            <option value="ALL">ALL</option>
            <option v-for="exchange in exchanges" :key="exchange.code" :value="exchange.code">{{ exchange.code }}</option>
          </select>
        </div>
      </div>

      <div class="selector">
        <div class="selector-cell">
          <p class="selector-label">Market</p>
        </div>
        <div class="selector-cell"> 
          <select v-model="selectedMarket" class="input-select" @change="fetchCharts()">
            <option value="ALL">ALL</option>
            <option v-for="market in markets" :key="market.code" :value="market.code">{{ market.code }}</option>          
          </select>
        </div>
      </div>

      <div class="selector">
        <div class="selector-cell">
          <p class="selector-label">Timeframe</p>
        </div>
        <div class="selector-cell"> 
          <select v-model="selectedTimeframe" class="input-select" @change="fetchCharts()">
            <option value="ALL">ALL</option>
            <option v-for="timeframe in timeframes" :key="timeframe.code" :value="timeframe.code">{{ timeframe.code }}</option>          
          </select>
        </div>
      </div>

      <div class="selector">
        <div class="selector-cell">
          <p class="selector-label">Pattern</p>
        </div>
        <div class="selector-cell"> 
          <select v-model="selectedPattern" class="input-select" @change="fetchCharts()">
            <option value="ALL">ALL</option>
            <option v-for="pattern in patterns" :key="pattern.code" :value="pattern.code">{{ pattern.code }}</option>          
          </select>
        </div>
      </div>

      <div class="selector">
        <div class="selector-cell">
          <p class="selector-label">Model</p>
        </div>
        <div class="selector-cell"> 
          <select v-model="selectedModel" class="input-select" @change="fetchCharts()">
            <option value="ALL">ALL</option>
            <option v-for="model in models" :key="model.code" :value="model.code">{{ model.code }}</option>          
          </select>
        </div>
      </div>

      <div class="selector">
        <div class="selector-cell">
          <p class="selector-label">Loop lengh</p>
        </div>
        <div class="selector-cell"> 
          <select v-model="selectNumberOfChartsInLoop" class="input-select">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
        </div>
      </div>

      <div class="selector">
        <div class="selector-cell">
          <p class="selector-label">Auto interval</p>
        </div>
        <div class="selector-cell"> 
          <select v-model="selectInterval" class="input-select">
            <option value="3000">3</option>
            <option value="5000">5</option>
            <option value="10000">10</option>
            <option value="20000">20</option>
          </select>
        </div>
      </div>

    </div>



    <!-- <div class="row">
      <div class="row main-area">
        <div class="selector">
          <div class="selector-cell">
            <p class="selector-label">Chart</p>
          </div>
          <div class="selector-cell"> 
            <TestAutoCounter> </TestAutoCounter>
          </div>
        </div>
      </div>
    </div> -->


    <div class="column middle-column">
      <!-- <div class="row main-area"> -->

        <div class="picture-container">
          <S3Image class="picture-img" :bucketName="'pattern-detector'" :fileNames="[displayedChart.fileName]" />
        </div>

      <!-- </div> -->
    </div>
    
    <div class="column right-column">
      <div class="detailed-info">
        <ChartDetailedInfo :chartDetailedInfo="selectedChartDetailedInfo" />
      </div>

      
      <div class="div-button-area"> 
      
        <div class="div-button"> 
          <button class="button" @click="previous()" >Prev</button>
        </div> 
        <div class="div-button"> 
          <label>
            Auto:
            <input type="checkbox" v-model="auto"/>
          </label>
          <!-- <button class="button" @click="fetchCharts()" >Auto</button> -->
        </div>
        <div class="div-button"> 
          <button class="button" @click="next()">Next</button>
        </div>
        <div class="div-button"> 
          <button class="button" @click="fetchCharts()">Refresh</button>
        </div>
      </div>
    </div>


  </div> 

</template>

  
<script setup>

  //import { ref, watch, onMounted } from 'vue';
  import { ref, onMounted,  watch } from 'vue';
  import axios from 'axios'; 
  // import TestComponent from '@/components/TestComponent.vue';
  import S3Image from '@/components/S3Image.vue';
  import ChartDetailedInfo from '@/components/ChartDetailedInfo.vue';

  //  import TestAutoCounter from '@/components/TestAutoCounter.vue';

  // Component name
  //const componentName = 'ChartsList'; 
  const selectedExchange = ref('ALL');
  const selectedMarket = ref('ALL');
  const selectedTimeframe = ref('ALL');
  const selectedPattern = ref('ALL');
  const selectedModel = ref('ALL');
  const selectedChart = ref(null);
  const exchanges = ref([]);
  const markets = ref([]);
  const timeframes = ref([]);
  const patterns = ref([]);
  const models = ref([]);
  const charts = ref([]);
  const selectedChartDetailedInfo = ref(null); 
 
  const auto = ref(false);
  let intervalId;

  let selectNumberOfChartsInLoop = ref(20);
  let selectInterval = ref(3000);
//  const counter = ref(0);

//  const displayedChart = [selectedChart.value] ;//['qas/detections/output_woo_PERP_1000FLOKI_USDT_30M_2023-12-16-15-30-00_1702740600_2023-12-22-21-00-00_1703278800_model-p00004-00_best.pt_0.6_416.png']
  const displayedChart = ref({
    index: 0,
    fileName: 'qas/detections/output_woo_PERP_1000FLOKI_USDT_30M_2023-12-16-15-30-00_1702740600_2023-12-22-21-00-00_1703278800_model-p00004-00_best.pt_0.6_416.png'
  });

  // Mock data for pictures
  // const pictures = ref([
  //   { id: 1, url: require('@/assets/charts/output_woo_PERP_1000SATS_USDT_5M_model-p00007-00_best.pt_0.5_416_2024-03-26-16-45-00_1711471500_2024-03-27-09-15-00_1711530900 copy.png') }, // Replace with actual picture paths
  //   // { id: 2, url: require('@/assets/charts/output_woo_PERP_1000SATS_USDT_5M_model-p00007-00_best.pt_0.5_416_2024-03-26-16-45-00_1711471500_2024-03-27-09-15-00_1711530900 copy.png') },
  //   // { id: 3, url: require('@/assets/charts/output_woo_PERP_1000SATS_USDT_5M_model-p00007-00_best.pt_0.5_416_2024-03-26-16-45-00_1711471500_2024-03-27-09-15-00_1711530900 copy.png') },
  // ]);

// Navigation functions
const previous = async () => {
  // Logic for navigating to the previous picture
  let currentIndex = displayedChart.value.index;
  console.log("before : ", displayedChart.value);
  console.log("displayedChart.value.index : ", displayedChart.value.index);
  console.log("charts.value.length : ", charts.value.length);

  if (displayedChart.value.index > 0 ) {
    displayedChart.value.index = currentIndex - 1;
    displayedChart.value.fileName = charts.value[currentIndex - 1].file_name;
    selectedChartDetailedInfo.value = charts.value[currentIndex - 1]
    console.log("after : ", displayedChart.value)
  }
  else {
    await fetchCharts();
    displayedChart.value.index = selectNumberOfChartsInLoop.value - 1;
    displayedChart.value.fileName = charts.value[selectNumberOfChartsInLoop.value - 1].file_name;
    selectedChartDetailedInfo.value = charts.value[selectNumberOfChartsInLoop.value - 1]
    console.log("after : ", displayedChart.value)   
  }

};

const next = async () => {
  // Logic for navigating to the next picture
  let currentIndex = displayedChart.value.index;
  console.log("before : ", displayedChart.value);
  console.log("displayedChart.value.index : ", displayedChart.value.index);
  console.log("charts.value.length : ", charts.value.length);

  if (displayedChart.value.index < (charts.value.length - 1) && displayedChart.value.index < (selectNumberOfChartsInLoop.value - 1)) {
    displayedChart.value.index = currentIndex + 1;
    displayedChart.value.fileName = charts.value[currentIndex + 1].file_name;
    selectedChartDetailedInfo.value = charts.value[currentIndex + 1]
    console.log("after : ", displayedChart.value)
  } else {
    await fetchCharts();
    displayedChart.value.index = 0;
    displayedChart.value.fileName = charts.value[0].file_name;
    selectedChartDetailedInfo.value = charts.value[0]
    console.log("after : ", displayedChart.value)   
  }
};

watch(auto, (newValue) => {
  if (newValue) {
    intervalId = setInterval(() => {
      //counter.value++;
      next();
    }, selectInterval.value);
  } else {
    clearInterval(intervalId);
  }
});

// const auto = async () => {
//   await fetchCharts();
// //  displayedChart
// };


// onMounted function

  onMounted(async () => {
    await fetchExchanges();
    await fetchMarkets();
    await fetchTimeframes();
    await fetchPatterns();
    await fetchModels();
    await fetchCharts();
            
  });

  const fetchExchanges = async () => {
    try {
      const response = await axios.get('http://runtime-dev.devnull.group:8000/exchanges/');
      exchanges.value = response.data;
      console.log("Fetched exchanges:", exchanges.value);
    } catch (error) {
      console.error('Error fetching exchanges:', error);
    }
  }

  const fetchTimeframes = async () => {
    try {
      const response = await axios.get('http://runtime-dev.devnull.group:8000/timeframes/');
      timeframes.value = response.data;
      console.log("Fetched timeframes:", timeframes.value);
    } catch (error) {
      console.error('Error fetching timeframes:', error);
    }
  }


  const fetchPatterns = async () => {
    try {
      const response = await axios.get('http://runtime-dev.devnull.group:8000/patterns/');
      patterns.value = response.data;
      console.log("Fetched patterns:", patterns.value);
    } catch (error) {
      console.error('Error fetching patterns:', error);
    }
  }

  const fetchModels = async () => {
    try {
      const response = await axios.get('http://runtime-dev.devnull.group:8000/models/');
      models.value = response.data;
      console.log("Fetched models:", models.value);
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  }


  const fetchMarkets = async () => {
    selectedMarket.value = 'ALL';
    //console.log("selectedExchange.value:", selectedExchange.value);
    if (!selectedExchange.value) return;
    // if (selectedExchange.value == 'ALL') {
    //   console.log("selectedExchange.value:", selectedExchange.value);
    //   console.log("selectedMarket.value:", selectedMarket.value);
    //   selectedMarket.value = 'ALL';
    //   console.log("selectedMarket.value:", selectedMarket.value);
    // } 
    try {
      const response = await fetch(`http://runtime-dev.devnull.group:8000/exchanges/${selectedExchange.value}/markets`);
      const data = await response.json();
      markets.value = data; // Assuming your API response is an array of market objects with a 'name' property
      console.log("in fetchMarkets()");
      console.log(markets.value);
    } catch (error) {
      console.error('Error fetching markets:', error);
    }
  };

  const fetchCharts = async () => {
    //console.log("selectedExchange.value:", selectedExchange.value);
    if (!selectedExchange.value) return;
    // if (selectedExchange.value == 'ALL') {
    //   console.log("selectedExchange.value:", selectedExchange.value);
    //   console.log("selectedMarket.value:", selectedMarket.value);
    //   selectedMarket.value = 'ALL';
    //   console.log("selectedMarket.value:", selectedMarket.value);
    // } 
    try {
      const response = await fetch(`http://runtime-dev.devnull.group:8000/exchanges/${selectedExchange.value}/markets/${selectedMarket.value}/timeframes/${selectedTimeframe.value}/patterns/${selectedPattern.value}/models/${selectedModel.value}`);
      const data = await response.json();
      charts.value = data; // Assuming your API response is an array of market objects with a 'name' property
      console.log("in fetchCharts()");
      console.log(charts.value);
      if (charts.value.length > 0) {
        selectedChart.value = charts.value[0].file_name
        displayedChart.value.index = 0;
        displayedChart.value.fileName = charts.value[0].file_name
        selectedChartDetailedInfo.value = charts.value[0]
      }
      else {
        displayedChart.value.index = 0;
        displayedChart.value.fileName = "/prd/assets/logo-trAIdng.png"
      }

    } catch (error) {
      console.error('Error fetching charts:', error);
    }
  };

//onMounted(fetchMarkets);

//watch(selectedExchange, fetchMarkets);
</script>

<!-- 
<script>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  
  export default {
    name: 'ChartsList',
    setup() {
      const items = ref([]);
  
      onMounted(async () => {
        try {
          const response = await axios.get('http://runtime-dev.devnull.group:8000/exchanges/');
          items.value = response.data;
          console.log("in ItemsList onMounted()");
          console.log(items.value);
        } catch (error) {
          console.error('Error fetching items:', error);
        }
      });
  
      return {
        items,
      };
    },
  };
</script> -->
  

<style scoped> 

  * {
    box-sizing: border-box;
  }
  .container {
    border: red;
    border-style: groove;
    margin: 0%;
    padding: 0%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    display: flex;
    /* height: 40%;
    width: 40%; */
    background-color: beige;
    height: 100vh;
  }
  
  .row {
    display: flex;
    justify-content: center;
    /* justify-content: space-evenly; */
    align-items: center;
    flex-direction: row;
    padding: 10px;
    background-color :rgb(255, 255, 255);
    border:rgb(255, 234, 0);
    border-style: groove;
  }
  
  .column {
    /* display: flex; */
    /* justify-content: left; */
    /* justify-content: space-evenly; */
    align-items: center;
    flex-direction: column;
    padding: 0px;
    background-color :rgb(255, 255, 255);
    border:rgb(255, 234, 0);
    border-style: groove;
    /* flex: 1; */
    display: flex;
    justify-content: center;
  }
  

  .input-parameters {
    background-color: #f0f0f0;
  }
/*   
  .input-select {
    margin: 0 10px;
  } */
  
  /* Styles for the select element */

  .selector {
    flex-direction: column;
    justify-content:center;
    background-color: rgb(43, 244, 224);
    width: 100%; 
    padding:10px;
    margin: 0px;
 
  }

  .selector-label {
    padding: 5px;
    margin: 0px;
  }

  .selector-cell {
    flex-direction: column;
    justify-content:stretch;
    background-color: rgb(136, 136, 235);
    border: 1px solid #e80e0e;
  }

  .input-select {
    appearance: none;
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    /* border-radius: 5px; */
    /* padding: 20px; */
    margin: 10px;
    /* font-size: large; */
    /* width: 200px; */
    cursor: pointer;
  }

  /* Styles for the options within the select */
  .input-select option {
    background-color: #fff;
    color: #333;
  }

  /* Styles for when the select is focused */
  .input-select:focus {
    outline: none;
    border-color: #6cb2eb;
    box-shadow: 0 0 5px rgba(108, 178, 235, 0.5);
  }


  .main-area {
    flex-grow: 1;
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Hide vertical scrolling */
  }
  
  .picture-container {
    display: flex;
    width: 100%; 
    height: 100%; 
    justify-content:center;
    margin: 10px;
    padding: 10px;
    /* width: 700px; 
    height: auto; */
  }
  
  .picture {
    margin: 0;
    /* width: 30%;  */
  }
  
  .picture-img {
    max-width: 100%; /* Ensure the image fits within the container */
  }
  


  .left-column, .right-column {
    flex: 0 0 auto; /* Fix width based on content */
  }

  .middle-column {
    flex: 1; /* Grow to fill available space */
  }

  .navigation-buttons {
    background-color: #f0f0f0;
  }

  .detailed-info {
    background-color: #f0f0f0;
    margin: 0px;
    padding: 10px;
  }

  .div-button-area {
    background-color: #f0f0f0;
    margin: 0px;
    padding: 10px;
    width: 100%;
  }

  
  
</style>
