<!-- App.vue -->
<template>
  <div id="app">
    <ChartsList />
  </div>
</template>

<script>
import ChartsList from './components/ChartsList.vue';
 
export default {
  name: 'App',
  components: {
    ChartsList,
  },
};
</script>

<style>

* {
  font-family: monospace;
  font-size: medium;
}


html {
  text-align: center;
  justify-content: center; /* Center horizontally */
  align-items: center; 
  background-color: rgb(15, 23, 178);
  flex-direction: column;
  align-content: flex-start;
  margin: 0px;
  padding: 0px;
  border: rgb(254, 250, 15);
  border-style: double;
}

body {
  text-align: center;
  justify-content: center; /* Center horizontally */
  align-items: center; 
  background-color: rgb(51, 214, 100);
  flex-direction: column;
  align-content: flex-start;
  margin: 0px;
  padding: 0px;
  border: firebrick;
  border-style: double;
}

h1 {
  background-color: rgb(214, 116, 51);
}

div {
  /* text-align: center;
  justify-content: center; 
  align-items: center; 
  flex-direction: column;
  align-content: flex-start; */
  background-color: rgb(255, 255, 255);
}

</style>



<!-- <template>
    <div class="container">
      <img :src="currentImageUrl" alt="Chart" class="image" />
      <div class="button-container">
        <button @click="prevImage()" class="button">Previous</button>
        <button @click="nextImage()" class="button">Next</button>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  
  export default {
    setup() {
      // Array to store image URLs
      const images = ref([]);
  
      // Index of the currently displayed image
      const currentIndex = ref(0);
  
      // Load images from the asset/charts folder
      const loadImages = () => {
        // Assuming you have image filenames in the asset/charts folder
        const requireContext = require.context('@/assets/charts', false, /\.(png|jpe?g|gif|svg)$/);
        const imagePaths = requireContext.keys().map((fileName) => requireContext(fileName));
        images.value = imagePaths.sort((a, b) => new Date(a.creationDate) - new Date(b.creationDate));
      };
  
      // Load images when the component is mounted
      loadImages();
  
      // Computed property to get the URL of the currently displayed image
      const currentImageUrl = computed(() => images.value[currentIndex.value]);
  
      // Function to navigate to the previous image
      const prevImage = () => {
        currentIndex.value = (currentIndex.value - 1 + images.value.length) % images.value.length;
      };
  
      // Function to navigate to the next image
      const nextImage = () => {
        currentIndex.value = (currentIndex.value + 1) % images.value.length;
      };
  
      return {
        currentImageUrl,
        prevImage,
        nextImage,
      };
    },
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Adjust this value as needed */
  }
  
  .image {
    max-width: 90%;
    max-height: 90vh; /* Adjust this value as needed */
  }
  

 .button-container {
  display: flex;
}

 .button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
  </style>
   -->