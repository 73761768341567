<template>
  <div>
    <img class="picture-chart" v-for="fileName in fileNames" :key="fileName" :src="getS3Url(fileName)" alt="Image">
  </div>
</template>

<script setup>
  import AWS from 'aws-sdk';
  import { defineProps } from 'vue';

  const props = defineProps(['bucketName', 'fileNames']);

  const getS3Url = (fileName) => {
    console.log("in S3Image component ", fileName, props.bucketName)
    const s3 = new AWS.S3();

    return s3.getSignedUrl('getObject', {
      Bucket: props.bucketName,
      Key: fileName,
      Expires: 3600 // Adjust expiration time as needed
    });
  };
</script>

<style scoped> 
  .picture-chart {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This ensures the image covers the entire container, preserving aspect ratio */
}
  
</style>
